import { render, staticRenderFns } from "./selectScience.vue?vue&type=template&id=d7036ed2&scoped=true&"
import script from "./selectScience.vue?vue&type=script&lang=js&"
export * from "./selectScience.vue?vue&type=script&lang=js&"
import style0 from "./selectScience.vue?vue&type=style&index=0&id=d7036ed2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7036ed2",
  null
  
)

export default component.exports